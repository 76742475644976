const TariffsFaqContent = () => {
  return (
    <div className="tariffs-faq-section__content">
      <h3>Как купить по цене продления?</h3>
      <p>
        Цена действительна для еще действующей подписки, в таком случае Вы
        можете продлить ее по более низкой цене, а на странице тарифов для этой
        подписки будет доступна кнопка продления, не кнопка покупки. Количество
        оставшихся по подписке дней не сгорает, а добавляется к дням, на которые
        Вы продлеваете подписку.
      </p>
      <h3>Автоматические списания</h3>
      <p>
        Мы не работаем с автоматическими списаниями, поэтому все покупки
        необходимо осуществлять вручную. Например, после окончания подписки с
        Вас не будут автоматически списаны средства за ее продление, продлить
        подписку Вам необходимо самому.
      </p>
    </div>
  )
}

export default TariffsFaqContent
