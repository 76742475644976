const BookmakersTableCol = ({ children, mobile_title }) => {
  return (
    <div
      className={`bookmaker-table__col bookmaker-table__col--size-3`}
      data-table-title-mobile={mobile_title}
    >
      {children}
    </div>
  )
}

export default BookmakersTableCol
