import Main from '../../components/layout/main/Main'
import { Link } from 'react-router-dom'

export const Privacy = () => {
  return (
    <Main>
      <h1 className="main__title">Политика конфиденциальности</h1>
      <p>
        Данный документ разъясняет использование сервисом приватной информации,
        собираемой при его использовании. Эта политика не распространяется на
        третьи стороны, которые не контролируются сервисом.
      </p>
      <p>
        Содержание
        <ul>
          <li>Сбор и использование информации</li>
          <li>Доступ к информации</li>
          <li>Cookies</li>
          <li>Хранение данных</li>
          <li>Безопасность</li>
          <li>Изменения</li>
          <li>Сбор и использование информации</li>
        </ul>
      </p>
      <p>
        Мы получаем и сохраняем некоторые типы информации, когда вы используете
        сервис. Автоматически сохраняются в логах на сервере некоторые данные о
        трафике полученные из вашего браузера, включая ваш IP адрес, информацию
        из cookies и запрошенной вами страницы. Мы используем данную информацию
        только для диагностики возникающих на сервере проблем, анализа
        статистики и администрирования веб-сайта.
      </p>
      <p>
        Мы собираем адреса, с которых к нам обращаются, агрегируем информацию о
        просматриваемых страницах, а также информацию, предоставленную самим
        пользователем. Собранные нами данные используются для улучшения качества
        нашего сервиса, и не предоставляются и не продаются другим организациям
        в коммерческих целях
      </p>
      <p>
        <b>Доступ к информации</b>
        <br />
        Доступ к информации осуществляется только по следующим правилам:
        <ul>
          <li>
            Администрация сервиса может иметь доступ к пользовательской
            информации во время исполнения своих должностных обязанностей.
          </li>
          <li>
            Мы можем раскрыть персональную информацию в случае, когда это
            необходимо для сотрудничества с силами закона и порядка; для
            исполнения пользовательского и других соглашений; для защиты прав,
            собственности или безопасности сервиса, наших сотрудников,
            пользователей. Это включает обмен информацией с другими компаниями и
            организациями для защиты от мошенничества.
          </li>
        </ul>
      </p>
      <p>
        За исключением указанного выше, вы будете проинформированы, когда ваша
        личная информация может быть разглашена третьим сторонам или
        использована для целей иных, чем те, для которых она была собрана, и вы
        будете иметь возможность предотвратить подобный обмен информацией.
      </p>
      <p>
        <b>Cookies</b>
        <br />
        Сookies это малые объемы информации, которые часто включают в себя
        анонимный идентификатор, который отправляется в ваш браузер
        веб-страницей, и сохраняется на жестком диске вашего компьютера. Cookies
        необходимы для использования сервиса.
      </p>
      <p>
        <b>Хранение данных</b>
        <br />
        Для хранения информации привлекаются третьи стороны для получения
        необходимого программного, технического, сетевого и прочего оборудования
        и обеспечения для поддержания работы сервиса.
      </p>
      <p>
        <b>Безопасность</b>
        <br />
        Информация о вашей учетной записи защищена паролем. Для защиты от
        несанкционированного доступа к вашей учетной записи вам необходимо
        производить процедуру выхода из приложения после окончания работы.
      </p>
      <p>
        <b>Изменения</b>
        <br />
        Администрация оставляет за собой право изменения данной политики.
      </p>
      <p>
        Настоящая Политика конфиденциальности информации действует в отношении
        всех данных и информации, которую Оператор сайта Workbet.info может
        получить о Вас как о лице, использующем сайт https://workbet.info/
        Настоящая Политика конфиденциальности информации регулирует порядок
        обработки (то есть сбора, хранения, использования, систематизации и
        т.п.) информации, полученной о Вас в ходе использования вами сайта
        https://workbet.info/ Любое использование вами сайта
        https://workbet.info/ (например: просмотр, регистрация на сайте,
        пользование сервисами сайта, акцепт оферт, получение услуг, реализуемых
        на сайте) означает, что Вы выразили безоговорочное согласие с настоящим
        документом и с указанными в нем условиями, поэтому Компания просит Вас
        внимательно ознакомиться с Политикой конфиденциальности. В случае
        несогласия с настоящими условиями Вы должны воздержаться от
        использования сайта https:// workbet.info/, а также прекратить процесс
        регистрации на данном сайте. В случае если какие-либо положения Политики
        конфиденциальности Вам не понятны или не ясны, Вам следует обратиться в
        службу поддержки, направив письмо на электронный адрес:
        info@workbet.info Для целей настоящего документа используются следующие
        понятия и определения: Политика конфиденциальности (Политика) –
        документ, регулирующий порядок обработки всех данных и информации о
        Пользователе сайтом; Обработка информации о Пользователе – любые
        действия, совершаемые Оператором сайта с данными и информацией о
        Пользователе. Например: сбор, запись, систематизация, накопление,
        хранение, уточнение (обновление, изменение), извлечение, использование,
        передача (предоставление доступа к информации/данным), обезличивание,
        блокирование, удаление, уничтожение данных. Обработка может
        осуществляться как с использованием средств автоматизации, так и без
        использования таковых; Сайт - интернет ресурс, расположенный по адресу{' '}
        <Link to="/">https://workbet.info/</Link>; Пользователь сайта
        (Пользователь) - лицо, использующее Сайт любым не запрещенным способом,
        то есть по тексту настоящей Политики Вы должны ассоциировать себя с
        Пользователем; Оператор сайта (Компания) – https://workbet.info/.
      </p>
      <h2>I. Информация Пользователей, которая обрабатывается Компанией</h2>
      <p>
        1.1. В рамках настоящей Политики под «информацией Пользователя»
        понимаются:
        <br />
        Информация, которую Пользователь предоставляет о себе самостоятельно при
        регистрации (создании учетной записи) или в процессе использования
        Сайта;
        <br />
        Данные, которые автоматически передаются сервисами Компании в процессе
        их использования с помощью, установленного на устройстве Пользователя
        программного обеспечения, в том числе IP-адрес, данные файлов cookie,
        данные об использовании сервисов и посещении других сайтов с
        использованием учетной записи на сайте Компании, данные о просмотре
        рекламных объявлений на Сайте, информация о браузере Пользователя (или
        иной программе, с помощью которой осуществляется доступ к Сервисам),
        технические характеристики оборудования и программного обеспечения,
        используемых Пользователем, дата и время доступа к Сервисам, и иная
        подобная информация;
        <br />
        Данные и информация, которые передаются Пользователем при обращении в
        службу поддержки Компании.
        <br />
        1.2 Настоящая Политика применяется только к информации, обрабатываемой в
        ходе использования Сайта. Компания не контролирует и не несет
        ответственность за обработку информации сайтами третьих лиц, на которые
        Пользователь может перейти по ссылкам, доступным на Сайте.
        <br />
        1.3. Компания не имеет возможности проверять достоверность информации,
        предоставляемой Пользователем, а также оценивать его дееспособность.
        Однако Компания исходит из того, что Пользователь предоставляет
        достоверную и достаточную информацию и поддерживает эту информацию в
        актуальном состоянии. В случае выявления фактов предоставления
        Пользователем недостоверной информации Компания имеет право
        заблокировать учетную запись Пользователя и ограничить его доступ к
        Сайту и его сервисам.
      </p>
      <h2>II. Правила обработки Компанией информации Пользователей</h2>
      <p>
        2.1. Компания собирает и хранит только ту информацию, которая необходима
        для работы Пользователя на Сайте или для исполнения соглашений и
        договоров с Пользователем.
        <br />
        2.2. Информацию Пользователя Компания обрабатывает в следующих целях:
        <br />
        Идентификация Пользователя в процессе использования им Сайта, для
        заключения соглашений и договоров с Компанией;
        <br />
        Предоставление Пользователю персонифицированного личного кабинета,
        исполнение соглашений и договоров;
        <br />
        Связь с Пользователем, в том числе направление уведомлений, запросов и
        информации, касающихся использования Сайта, исполнения соглашений и
        договоров, а также обработка запросов и заявок от Пользователя;
        <br />
        Улучшение качества услуг, предоставляемых на Сайте, увеличение удобства
        пользования Сайтом, разработка новых продуктов и услуг;
        <br />
        Проведение статистических и иных исследований.
        <br />
        2.3. Пользователь соглашается, что предоставленная им информация будет
        использована Компанией в целях персонализации контента на Сайте.
      </p>
      <h2>
        III. Условия использования информации, полученной от Пользователя.
      </h2>
      <p>
        Согласие на обработку персональных данных и их передачу третьим лицам
        <br />
        3.1. Пользователь соглашается, что часть предоставленной им информация
        (ник, адрес электронной почты) может быть доступна другим Пользователям
        Сайта и сервисов Компании. В случае необходимости скрыть какие-либо
        данные от других пользователей, Пользователю необходимо направить
        соответствующее письмо по адресу электронной почты: info@workbet.info
        или воспользоваться соответствующей функцией на Сайте.
        <br />
        3.2. Данные Пользователя в целях исполнения заключенных им на Сайте
        соглашений могут быть переданы Партнерам проекта. В случае необходимости
        такой передачи у Пользователя на Сайте запрашивается дополнительное
        согласие.
        <br />
        3.3. Компания вправе передать информацию Пользователя третьим лицам в
        следующих случаях:
        <br />
        Пользователь выразил согласие на такие действия или Передача необходима
        для использования Сайта и Сервисов Компании либо для исполнения
        определенного соглашения или договора с Пользователем или по запросу
        государственных органов, судебных органов и должностных лиц или в иных
        случаях, предусмотренных законодательством страны в юрисдикции, которой
        находится Пользователь.
        <br />
        3.4. В случае необходимости, по запросу Компании Пользователь обязуется
        предоставить Компании свои персональные данные. Персональными данными
        являются любые сведения, относящиеся прямо или косвенно к Пользователю,
        в том числе, но не исключая: фамилия, имя, отчество, дата и место
        рождения, паспортные данные, статус. При непредставлении по запросу
        Компании персональных данных, учетная запись Пользователя может быть
        заблокирована.
        <br />
        3.5. В случае передачи Компании своих персональных данных Пользователь
        выражает согласие на их обработку и хранение. Под обработкой
        персональных данных в настоящей Политике понимается совокупность
        действий (операций), совершаемых с использованием средств автоматизации
        или без использования таких средств с персональными данными, включая
        сбор, запись, систематизацию, накопление, хранение, уточнение
        (обновление, изменение), извлечение, использование, передачу
        (распространение, предоставление, доступ), обезличивание, блокирование,
        удаление, уничтожение персональных данных.
        <br />
        3.6. Пользователь несет ответственность за любую информацию,
        опубликованную им на Сайте, и соглашается, что эта информация будет
        доступна другим Пользователям. Пользователь соглашается, что любая
        информация, размещенная им на Сайте, может быть прочитана, собрана или
        использована Компанией, другими Пользователями и сторонними лицами (в
        случае если она находится в открытом доступе).
      </p>
      <h2>
        IV. Изменение и удаление информации. Отзыв согласия на обработку данных
      </h2>
      <p>
        4.1. Пользователь может в любой момент изменить (обновить, дополнить)
        предоставленную им информацию или ее часть, направив письмо на
        электронный адрес: info@workbet.info.
        <br />
        4.2. Пользователь может в любой момент удалить предоставленную им в
        рамках соответствующей учетной записи персональную информацию, направив
        письмо на электронный адрес: info@forking.bet
        <br />
        4.3. Права, предусмотренные пунктами 4.1. и 4.2. настоящей Политики
        могут быть ограничены в соответствии с требованиями законодательства. В
        частности, т акие ограничения могут предусматривать обязанность Компании
        сохранить измененную или удаленную Пользователем информацию на срок,
        установленный законодательством, и передать такую информацию в
        соответствии с законодательно установленной процедурой государственному
        органу.
        <br />
        4.4. Пользователь может в любой момент отозвать согласие на обработку
        своих данных, направив письмо на электронный адрес: info@workbet.info.
      </p>
      <h2>V. Использование файлов Cookie и счетчиков</h2>
      <p>
        5.1. Компания использует файлы Cookie для персонализации возможностей
        Сайта и сервисов Компании, для предоставления Пользователю
        персонализированных Сервисов, для таргетирования рекламы, в
        статистических и исследовательских целях, а также для улучшения работы
        сервисов и Сайта.
        <br />
        5.2. Пользователь осознает, что оборудование и программное обеспечение,
        используемые им для посещения сайтов в сети интернет могут обладать
        функцией запрещения операций с файлами Cookie (для любых сайтов или для
        определенных сайтов), а также удаления ранее полученных файлов Сookie.
        <br />
        5.3. Компания устанавливает, что пользование Сайтом и сервисами Компании
        возможно лишь при условии, что прием и получение файлов Cookie разрешены
        Пользователем. Используя Сайт и сервисы Компании, Пользователь
        соглашается на размещение в своем браузере файлов Cookie.
        <br />
        5.4. Структура файла Cookie, его содержание и технические параметры
        определяются Компанией и могут изменяться без предварительного
        уведомления Пользователя.
        <br />
        5.5. Счетчики, размещенные Компанией на Сайте и в Сервисах, могут
        использоваться для анализа файлов Cookie Пользователя, для сбора и
        обработки статистической информации об использовании Сервисов, а также
        для обеспечения работоспособности Сервисов в целом или их отдельных
        функций в частности. Технические параметры работы счетчиков определяются
        Компанией и могут изменяться без предварительного уведомления
        Пользователя.
      </p>
      <h2>
        VI. Ответственность за хранение данных, необходимых для пользования
        Сайтом Компании и сервисами Компании.
      </h2>
      <p>
        6.1. Пользователь обязан обеспечивать сохранность всех данных (логин,
        пароль, ID и т.д.) Пользователя, необходимые для пользования Сайтом и
        сервисами Компании.
        <br />
        6.2. При наличии информации или подозрений о неправомерном получении
        третьими лицами данных Пользователя, Пользователь обязан незамедлительно
        сменить пароль, используемый им для входа на Сайт Компании, а также
        сообщить Компании об указанных фактах.
        <br />
        6.3. Пользователь принимает на себя исключительную ответственность за
        частичное или полное пользование Сайтом и сервисами Компании при помощи
        уникального логина или номера ID и пароля.
      </p>
      <h2>VII. Меры, применяемые для защиты информации Пользователя</h2>
      <p>
        7.1. Компания принимает необходимые и достаточные организационные и
        технические меры для защиты информации Пользователя от неправомерного
        или случайного доступа, уничтожения, изменения, блокирования,
        копирования, распространения, а также от иных неправомерных действий с
        ней третьих лиц.
      </p>
      <h2>VIII. Срок хранения информации</h2>
      <p>
        8.1. Предоставленная Пользователем информация хранится в течении 6
        месяцев, с момента ее предоставления, за исключением случаев, указанных
        в п. 8.2. настоящей Политики.
        <br />
        8.2. В случае акцепта пользователем оферт, заключения соглашений с
        Компанией, предоставленная Пользователем информация хранится в течении
        всего периода действия оферты/соглашений, а также в течении 3 (трех) лет
        после прекращения действия оферты/соглашений.
        <br />
        8.3. В случае необходимости сокращения срока хранения предоставленной
        Пользователем информации, Пользователь может направить письмо с
        описанием ситуации на электронный адрес: info@workbet.info.
      </p>
      <h2>IX. Изменение Политики конфиденциальности</h2>
      <p>
        9.1. Компания имеет право вносить изменения в настоящую Политику
        конфиденциальности. При внесении изменений в актуальной редакции
        указывается дата последнего обновления. Новая редакция Политики вступает
        в силу с момента ее размещения, если иное не предусмотрено новой
        редакцией Политики. Действующая редакция постоянно доступна на Сайте.
        <br />
        9.2. В случае несогласия с изменениями в Политики конфиденциальности,
        Пользователь обязан удалить свою учетную запись. Продолжение пользования
        Сайтом и сервисами компании означает согласие с изменениями, внесенными
        в настоящую Политику.
      </p>
      <p>
        Настоящая политика вступает в силу с 01.11.2022 г.
        <br />
        На сайте <Link to="/">https://workbet.info/</Link>
      </p>
    </Main>
  )
}
