import { observer } from 'mobx-react-lite'
import { useApp } from '../../../../hook/useApp'

const AsideHeader = observer(() => {
  const { store } = useApp()

  const login = store.Auth.authUser.sub

  return (
    <div className="profile-aside__header">
      <div className="profile-aside__header-title">Профиль</div>
      <p className="profile-aside__header-login">{login}</p>
    </div>
  )
})

export default AsideHeader
