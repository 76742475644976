import { observer } from 'mobx-react-lite'
import { useApp } from '../../hook/useApp'

const ButtonWriteToUs = observer(({ children }) => {
  const { store } = useApp()

  function handlerClick() {
    store.UIInterface.changeStateModalWriteToUs({
      type: 'title',
      value: 'Связаться с нами'
    })
    store.UIInterface.changeStateModalWriteToUs({
      type: 'type',
      value: 'Обращение'
    })
    store.UIInterface.changeStateModalWriteToUs({
      type: 'visible',
      value: true
    })
    store.UIInterface.changeStateMenu()
  }

  return (
    <button
      className="lg-visible btn btn--size-md btn--theme-white-accent header__action-write"
      onClick={handlerClick}
    >
      {children}
    </button>
  )
})

export default ButtonWriteToUs
