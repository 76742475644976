import Main from '../../../components/layout/main/Main'

export const LegalRequests = () => {
  return (
    <Main>
      <h1 className="main__title">Юридические запросы</h1>
      <h2>Вопрос</h2>
      <p>Стоит ли мне бояться и что делать?</p>
      <h2>Кратко</h2>
      <p>
        Вы не пропустите момент, когда стоит бояться. Чем меньше вилочников, тем
        лучше для вилочников.
      </p>
      <h2>Подробно</h2>
      <p>
        Для рассмотрения юридических вопросов надо учитывать специфику. С одной
        стороны, есть юридическая практика (прецедент), согласно которой новые
        дела рассматриваются так, как уже рассматривались подобные дела ранее. А
        другой стороны есть субъективность трактовки законов — при желании можно
        и оправдать, и осудить в соответствии с возможностями обвинителей или
        адвокатов. И этот самый прецедент можно создать, чтобы аналогичные дела
        запустить на конвейер.
      </p>
      <p>
        Если правительство захочет урегулировать деятельность вилочников, то,
        вероятней, это начнется с показательных наказаний известных в этой сфере
        людей или организаций (например, обязует сканеры раскрывать отчетность
        дохода игроков). Разные сферы интернет-заработка попадали под внимание
        правительства тогда, когда явление становилось достаточно массовым для
        получения с него прибыли. Например, арбитраж трафика или фриланс.
        Поэтому не забываем, что деньги любят тишину.
      </p>
    </Main>
  )
}
