export const Button = ({ children, handler, theme, size, className }) => {
  const defaultTheme = 'btn--theme-white'
  const themes = {
    white: 'btn--theme-white',
    simple: 'btn--theme-simple',
    dark: 'btn--theme-dark',
    'white-accent': 'btn--theme-white-accent',
    gray: 'btn--theme-gray',
    disabled: 'btn--theme-disabled'
  }

  const defaultSize = 'btn--size-md'
  const sizes = {
    sm: 'btn--size-sm',
    md: 'btn--size-md',
    st: 'btn--size-st',
    lg: 'btn--size-lg'
  }

  const classTheme = themes[theme] || defaultTheme
  const classSize = sizes[size] || defaultSize

  return (
    <button
      className={`btn ${classTheme} ${classSize} ${className || ''}`}
      onClick={handler}
    >
      {children}
    </button>
  )
}
