import Main from '../../../components/layout/main/Main'

export const HowRecharge = () => {
  return (
    <Main>
      <h1 className="main__title">Как пополнить счет</h1>
      <p>
        Для начала регистрируйтесь в букмекерской конторе, после чего при
        необходимости пройдите верификацию личности, после этого зайдите в свой
        личный кабинет, и в разделе «пополнить счет» выберите способ оплаты, и
        далее произведите оплату.
      </p>
    </Main>
  )
}
