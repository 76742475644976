import { observer } from 'mobx-react-lite'
import ButtonIntroClose from '../../buttons/ButtonIntroClose'
import ButtonIntroMore from '../../buttons/ButtonIntroMore'
import IntroPicture from './IntroPicture'

const IntroBody = observer(({ store }) => {
  const introText = `Самые выгодные "профиты" на спорт от WorkBet`

  const stateIntro = store.UIInterface.stateIntro

  return (
    <>
      {stateIntro ? (
        <div className="intro__body">
          <ButtonIntroClose store={store} />
          <div className="intro__body-content">
            <h1 className="intro__body-content-title">{introText}</h1>
            <ButtonIntroMore visible={true} text="Подробнее" url="#" />
          </div>
          <IntroPicture />
          <ButtonIntroMore visible={false} text="Подробнее" url="#" />
        </div>
      ) : (
        ''
      )}
    </>
  )
})

export default IntroBody
