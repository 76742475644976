const BookmakersTableCheckbox = () => {
  return (
    <label className="checkbox-label bookmaker-table__domain">
      <input
        className="checkbox-label__control"
        type="checkbox"
        name="domain"
        value="Ввести свой домен"
      />
      <span className="checkbox-label__inner">
        <span className="checkbox-label__inner-icon"></span>
        <span className="checkbox-label__inner-text">Ввести свой домен</span>
      </span>
    </label>
  )
}

export default BookmakersTableCheckbox
