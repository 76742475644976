export const Profile = ({ children }) => {
  return (
    <div className="btn-group header__action">
      <div className="btn-group__inner">{children}</div>
    </div>
  )
}
export const ProfileItem = ({ children, theme, handler }) => {
  return (
    <button
      className={`btn btn--size-md btn--theme-${theme} btn-group__item`}
      onClick={handler}
    >
      {children}
    </button>
  )
}
