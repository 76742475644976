import { makeObservable, configure, observable, action, computed } from 'mobx'

import { checkIsProUser } from '../helpers/index'

import { axiosPublic } from '../modules/axios/axiosPublic'
import { listForkStatuses } from '../constants'

configure({ enforceActions: 'observed' })

class UIInterface {
  rootStore = null

  isShowMenu = false
  isShowCalculator = false
  isProUser = false

  windowWidth = window.innerWidth

  listForkStatuses = listForkStatuses

  autoRefresh = true

  toast = null

  logoutMessage = null

  tooltipDelay = 1500

  modalWriteToUs = {
    title: 'Связаться с нами',
    visible: false,
    type: 'Обращение',
    responseMessage: '',
    status: true
  }

  stateIntro = false

  constructor(rootStore) {
    makeObservable(this, {
      isShowMenu: observable,
      isShowCalculator: observable,
      isProUser: observable,
      windowWidth: observable,
      stateIntro: observable,
      listForkStatuses: observable,
      autoRefresh: observable,
      toast: observable,
      tooltipDelay: observable,
      modalWriteToUs: observable,
      logoutMessage: observable,

      isMobile: computed,
      is800px: computed,

      changeStateMenu: action,
      changeStateCalculator: action,
      changeWindowWidth: action,
      changeStateProUser: action,
      switchStateIntro: action,
      changeStateAutoRefresh: action,
      setToast: action,
      setLogoutMessage: action,
      removeLogoutMessage: action,
      changeStateModalWriteToUs: action,
      sendWriteToUsMessage: action
    })

    window.onresize = () => {
      this.changeWindowWidth(window.innerWidth)
    }

    this.rootStore = rootStore
    this.stateIntro = this.getIntroStatus()
    this.changeStateProUser()
  }

  changeStateMenu() {
    this.isShowMenu = !this.isShowMenu
  }

  changeStateCalculator() {
    this.isShowCalculator = !this.isShowCalculator
  }

  async changeStateProUser() {
    this.isProUser = checkIsProUser(this.rootStore.Auth.authUser)
  }

  get isMobile() {
    return this.windowWidth < 1025
  }

  get is800px() {
    return this.windowWidth < 800
  }

  changeWindowWidth(val) {
    this.windowWidth = val
  }

  switchStateIntro() {
    this.stateIntro = !this.stateIntro
    localStorage.setItem('IntroStatus', JSON.stringify(this.stateIntro))
  }

  getIntroStatus() {
    const defaultState = true
    const localState = localStorage.getItem('IntroStatus')
    if (localState) {
      return JSON.parse(localState)
    }

    return defaultState
  }

  changeStateAutoRefresh() {
    this.autoRefresh = !this.autoRefresh
  }

  setToast(payload) {
    this.toast = payload
  }

  setLogoutMessage() {
    this.logoutMessage =
      'Ваш логин и пароль для доступа в систему был использован на другом устройстве или в другом браузере. В связи с чем доступ на этом устройстве заблокирован'
  }

  removeLogoutMessage() {
    this.logoutMessage = null
  }

  changeStateModalWriteToUs({ type, value }) {
    this.modalWriteToUs[type] = value
    if (type === 'visible' && value) {
      this.modalWriteToUs.status = null
    }
  }

  async sendWriteToUsMessage(payload) {
    const res = await axiosPublic.post('/tech/writeToUs', {
      ...payload,
      captcha: payload.captcha?.response || 'test',
      messageType: this.modalWriteToUs.type
    })

    const { error, message } = res.data
    if (error) {
      this.changeStateModalWriteToUs({
        type: 'responseMessage',
        value: message
      })
      return false
    }

    this.changeStateModalWriteToUs({ type: 'responseMessage', value: '' })
    this.changeStateModalWriteToUs({ type: 'status', value: true })

    return true
  }
}

export default UIInterface
