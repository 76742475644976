import { observer } from 'mobx-react-lite'
import IntroAppeal from './IntroAppeal'
import IntroBody from './IntroBody'

const Intro = observer(({ store }) => {
  return ''

  const apealText = `Самые выгодные "профиты" на спорт от WorkBet`

  return (
    <div className="intro">
      <div className="container intro__container">
        <IntroAppeal text={apealText} store={store} />
        <IntroBody store={store} />
      </div>
    </div>
  )
})

export default Intro
