import styles from '../autorization.module.css'
import { observer } from 'mobx-react-lite'
import { useFormik } from 'formik'
import { useApp } from '../../../hook/useApp'
import { classNames } from 'primereact/utils'
import { useParams } from 'react-router-dom'

const ChangePassword = observer(({ visible, handlerClose }) => {
  const { store } = useApp()

  const { id } = useParams()

  const formik = useFormik({
    initialValues: {
      password: ''
    },
    validate: (data) => {
      let errors = {}

      if (!data.password) errors.password = 'Пароль обязателен для заполнения'
      return errors
    },
    onSubmit: async (data) => {
      await store.Auth.confirmChangePassword(data, id)
    }
  })

  const authError = store.Auth.authError ? (
    <p className="p-error">{store.Auth.authError}</p>
  ) : (
    ''
  )

  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name])
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <small className="p-error">{formik.errors[name]}</small>
      )
    )
  }

  if (!visible) return ''

  return (
    <div className={styles['modal-window']}>
      <div
        className={styles['modal-window__close']}
        onClick={handlerClose}
      ></div>
      <div className={styles['modal-window__title']}>Новый пароль</div>
      <form onSubmit={formik.handleSubmit}>
        <div className={styles['modal-window_row']}>
          <input
            type="password"
            placeholder="Новый пароль"
            className={classNames({ 'p-error': isFormFieldValid('password') })}
            name="password"
            value={formik.values.password}
            onChange={formik.handleChange}
          />
          {getFormErrorMessage('password')}
        </div>
        <div
          className={
            styles['modal-window_row'] +
            ' ' +
            styles['modal-window_row--center']
          }
        >
          <div className={styles['modal-window_col']}>
            {authError}
            <button type="submit" className="app-button">
              Отправить
            </button>
          </div>
        </div>
      </form>
    </div>
  )
})

export { ChangePassword }
