import { observer } from 'mobx-react-lite'
import { Dropdown } from 'primereact/dropdown'
import { useApp } from '../../../../hook/useApp'
import { IconAction } from '../../../ui/icons/Icons'
import InputCalculator from '../../../inputs/InputCalculator'
import { formatCoef } from '../../../../helpers'

export const CalculatorHeader = () => {
  return (
    <CalculatorList>
      <CalculatorColumn>
        <p className="event-card__title">Коэф.</p>
      </CalculatorColumn>
      <CalculatorColumn>
        <p className="event-card__title">Сумма</p>
      </CalculatorColumn>
      <CalculatorColumn>
        <p className="event-card__title">Валюта</p>
      </CalculatorColumn>
      <CalculatorColumn>
        <p className="event-card__title">Доход</p>
      </CalculatorColumn>
    </CalculatorList>
  )
}
export const CalculatorBody = observer(({ index, isPlus }) => {
  const { store } = useApp()

  function changeSumBet(val) {
    store.SportEvent.changeSumBetRow({ val, index })
  }
  function changeKefBet(val) {
    store.SportEvent.changeKefBetRow({ val, index })
  }

  const listCurrency = store.SportEvent.currenciesList
  const currentCurrency = store.SportEvent.currentCurrency

  const { coef, sumBet, profit } = store.SportEvent.calculatorValues[index]

  return (
    <CalculatorList>
      <CalculatorColumn>
        <div
          className={`input-profit${isPlus !== undefined ? ` input-profit--${isPlus ? 'plus' : 'minus'}` : ''} event-card__data-list-coef`}
        >
          <InputCalculator
            className="input-el input-profit__el"
            disabled={true}
            value={formatCoef(coef)}
            handler={changeKefBet}
          />
        </div>
      </CalculatorColumn>
      <CalculatorColumn className="event-card__data-list-col--size-30">
        <InputCalculator
          className="input-el event-card__coefficient-input"
          value={sumBet}
          handler={changeSumBet}
        />
      </CalculatorColumn>
      <CalculatorColumn className="event-card__data-list-col--size-20">
        <Dropdown value={currentCurrency} options={listCurrency} />
      </CalculatorColumn>
      <CalculatorColumn>{profit}</CalculatorColumn>
    </CalculatorList>
  )
})
export const CalculatorFooter = observer(() => {
  const { store } = useApp()

  function changeCurrency(e) {
    store.SportEvent.changeCurrency(e.target.value)
  }
  function changeSumBet(val) {
    store.SportEvent.updateSumBet(val)
  }
  function resetCoefficients() {
    store.SportEvent.resetCoefficients()
  }

  const sumBet = store.SportEvent.fullSumBet
  const currentCurrency = store.SportEvent.currentCurrency
  const listCurrency = store.SportEvent.currenciesList

  return (
    <CalculatorList>
      <CalculatorColumn>
        <div className="width--max display--flex row--center pad-r--16px">
          <button
            className="event-card__btn-invisible active-animation"
            onClick={resetCoefficients}
          >
            <IconAction name="refresh-black" />
          </button>
        </div>
      </CalculatorColumn>
      <CalculatorColumn className="event-card__data-list-col--size-30">
        <InputCalculator
          className="input-el event-card__coefficient-input"
          value={sumBet}
          handler={changeSumBet}
        />
      </CalculatorColumn>
      <CalculatorColumn className="event-card__data-list-col--size-20">
        <Dropdown
          value={currentCurrency}
          options={listCurrency}
          onChange={changeCurrency}
        />
      </CalculatorColumn>
      <CalculatorColumn></CalculatorColumn>
    </CalculatorList>
  )
})

export const CalculatorList = ({ children }) => {
  return <div className="event-card__data-list">{children}</div>
}
export const CalculatorColumn = ({ children, className }) => {
  return (
    <div
      className={`event-card__data-list-col${className ? ` ${className}` : ''}`}
    >
      {children}
    </div>
  )
}
