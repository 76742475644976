const Main = ({ children }) => {
  return (
    <main className="main" role="main">
      <section className="feed-section">
        <div className="container">{children}</div>
      </section>
    </main>
  )
}

export default Main
