import { NavLink } from 'react-router-dom'

const AsideNavigationLink = ({ children, url }) => {
  const setActive = ({ isActive }) =>
    isActive
      ? 'profile-aside__nav-link is-active normal-a'
      : 'profile-aside__nav-link normal-a'

  return (
    <li className="profile-aside__nav-item">
      <NavLink className={setActive} to={`/profile/${url}`}>
        {children}
      </NavLink>
    </li>
  )
}

export default AsideNavigationLink
