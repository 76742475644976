import imgPayment from '../../../../assets/payments/payments.png'

const TariffsFaqPicture = () => {
  return (
    <picture className="tariffs-faq-section__payments">
      <img src={imgPayment} alt="Способы оплаты" />
    </picture>
  )
}

export default TariffsFaqPicture
