import { useApp } from '../../../../hook/useApp'
import { observer } from 'mobx-react-lite'
import React from 'react'

const FilterParams = observer(({ visible = true }) => {
  const { store } = useApp()

  const filterParams = store.Filter.filterParams

  if (!visible) return ''
  return (
    <div className="filter-params">
      <p>
        {filterParams.map(({ id, label, value, additional }, index) => {
          return (
            <React.Fragment key={id}>
              {label}: <b>{value}</b>
              {additional ? ' (' : ''}
              {additional ? (
                <span dangerouslySetInnerHTML={{ __html: additional }}></span>
              ) : (
                ''
              )}
              {additional ? ')' : ''}
              {index === filterParams.length - 1 ? '' : ', '}
            </React.Fragment>
          )
        })}
      </p>
    </div>
  )
})

export default FilterParams
