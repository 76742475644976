import { observer } from 'mobx-react-lite'

import { IconAction } from '../../ui/icons/Icons'

const IntroAppeal = observer(({ text, store }) => {
  function handlerClick() {
    store.UIInterface.switchStateIntro()
  }

  const stateIntro = store.UIInterface.stateIntro

  return (
    <div className="intro__appeal">
      <p>{text}</p>
      <div className="intro__switch" onClick={handlerClick}>
        {stateIntro ? (
          <IconAction name="intro-arrow-top" className="intro__icon" />
        ) : (
          <IconAction name="intro-arrow-bottom" className="intro__icon" />
        )}
      </div>
    </div>
  )
})

export default IntroAppeal
