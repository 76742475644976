export const ForkNotFound = ({ children }) => {
  return (
    <div className="forks-not-found">
      {children ? (
        children
      ) : (
        <>
          <h2>На данный момент новых данных нет, но это не надолго!</h2>
          <p>
            Периодически обновляйте данную страницу или вернитесь сюда немного
            позднее. Желаем Вам удачи!
          </p>
        </>
      )}
    </div>
  )
}
