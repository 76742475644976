import imgSports from '../../../../assets/balls-sports.png'
import imgDiagram from '../../../../assets/diagram.png'
import imgAdvantage from '../../../../assets/advantage.png'

export const TariffsFaqInfo = () => {
  return (
    <>
      <div className="tariffs-faq-section__title">
        * При активной платной подписке и при переходе на более дорогой тариф -
        скидка 25%
      </div>
      <div className="tariffs-faq-section__info">
        <div className="tariffs-faq-section__info-item">
          <div className="tariffs-faq-section__info-icon">
            <img src={imgSports} alt="6 видов спорта" />
          </div>
          <p>6 видов спорта</p>
        </div>
        <div className="tariffs-faq-section__info-item">
          <div className="tariffs-faq-section__info-icon">
            <img src={imgDiagram} alt="Профит до 9999" />
          </div>
          <p>Профит до ∞</p>
        </div>
        <div className="tariffs-faq-section__info-item">
          <div className="tariffs-faq-section__info-icon">
            <img src={imgAdvantage} alt="Только легальные БК" />
          </div>
          <p>Только легальные БК</p>
        </div>
      </div>
    </>
  )
}
