import ButtonCardAction from '../../../../buttons/ButtonCardAction'

import { IconAction } from '../../../../ui/icons/Icons'

import { Link } from 'react-router-dom'

export const ActionList = ({ children, align }) => {
  return (
    <div
      className={`event-card__btn-list${align ? ` event-card__btn-list--${align}` : ''}`}
    >
      {children}
    </div>
  )
}

export const ButtonCalculator = ({ active = false, handler, isDisabled }) => {
  return (
    <button
      className={`js-event-btn-calculator btn btn--size-md btn--theme-${isDisabled ? 'disabled' : 'simple'} event-card__btn-list-item`}
      type="button"
      onClick={handler}
    >
      <span className="btn__icon-wrapper btn__icon-wrapper--ltr">
        {active ? (
          <IconAction name="close" className="icon btn__icon" />
        ) : (
          <IconAction name="calculator" className="icon btn__icon" />
        )}
      </span>
      Калькулятор
    </button>
  )
}

export const ButtonAllForks = ({ visible, mobile = false, countBets, url }) => {
  if (!visible) return ''

  if (countBets < 2) {
    if (!mobile)
      return <ButtonCardAction isDisabled={true}>Все вилки</ButtonCardAction>
    return ''
  }

  const text = countBets < 2 ? 'Все вилки' : `Все вилки (${countBets})`

  return (
    <Link to={url} className="event-card__href normal-a">
      {mobile ? (
        text
      ) : (
        <ButtonCardAction theme="selection">{text}</ButtonCardAction>
      )}
    </Link>
  )
}
