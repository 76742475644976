const InputCardBetCoef = ({ isPlus, value }) => {
  return (
    <div
      className={`input-profit${isPlus !== undefined ? ` input-profit--${isPlus ? 'plus' : 'minus'}` : ''} event-card__bo-profit`}
    >
      <input
        className="input-el input-profit__el"
        type="text"
        name=""
        value={value}
        readOnly
      />
    </div>
  )
}

export default InputCardBetCoef
