import Main from '../../../components/layout/account/Main'
import TariffsSection from '../../../components/pages/tarifs/section/TariffsSection'
import TariffsSectionTitle from '../../../components/pages/tarifs/section/TariffsSectionTitle'
import TariffsFaq from '../../../components/pages/tarifs/faq/TariffsFaq'

import { TariffsFaqInfo } from '../../../components/pages/tarifs/faq/TariffsFaqInfo'

import TariffsFaqContent from '../../../components/pages/tarifs/faq/TariffsFaqContent'
import TariffsFaqPicture from '../../../components/pages/tarifs/faq/TariffsFaqPicture'

import { TariffSlider } from '../../../components/pages/tarifs/slider/TariffSlider'

const Tariffs = () => {
  return (
    <Main>
      <TariffsSection>
        <TariffsSectionTitle>Базовые тарифы</TariffsSectionTitle>
        <TariffSlider />
      </TariffsSection>
      <TariffsFaq>
        <TariffsFaqInfo />
        <TariffsFaqPicture />
        <TariffsFaqContent />
      </TariffsFaq>
    </Main>
  )
}

export { Tariffs }
