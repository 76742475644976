const ProfileCardFormPromocode = () => {
  return (
    <form className="profile-settings-card__promocode">
      <input
        className="input-el input-el--size-md input-el--theme-white profile-settings-card__promocode-input"
        type="text"
        name="promocode"
        placeholder="Введите промокод для активации (не обязательно)"
      />
      <button
        className="btn btn--theme-dark btn--size-st profile-settings-card__promocode-btn"
        type="submit"
      >
        Активировать
      </button>
    </form>
  )
}

export default ProfileCardFormPromocode
