import Main from '../../components/layout/main/Main'

import { useApp } from '../../hook/useApp'

import { Link, useParams } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'

export const AccountActivate = observer(() => {
  const { store } = useApp()
  const statusActivate = store.Auth.statusActivate
  const { id } = useParams()

  useEffect(() => {
    store.Auth.activateAccount(id)
  }, [])

  return (
    <Main>
      <h1>Активация аккаунта</h1>
      <p>{statusActivate}</p>
      <Link to="/signin">На главную</Link>
    </Main>
  )
})
