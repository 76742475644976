import AsideNavigationLink from './AsideNavigationLink'

const AsideNavigation = () => {
  const urls = [
    { id: 1, text: 'Аккаунт', url: 'account' },
    { id: 2, text: 'Настройки', url: 'settings' },
    { id: 6, text: 'Платежи', url: 'payments' }
  ]

  return (
    <nav className="profile-aside__nav">
      <ul className="profile-aside__nav-list">
        {urls.map((item) => {
          return (
            <AsideNavigationLink key={item.id} url={item.url}>
              {item.text}
            </AsideNavigationLink>
          )
        })}
      </ul>
    </nav>
  )
}

export default AsideNavigation
