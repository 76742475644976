import { IconAction } from '../ui/icons/Icons'

import { Link } from 'react-router-dom'
import { observer } from 'mobx-react-lite'

import { formatDate, formatTime, unixDateToDate } from '../../helpers'

const EventTitle = observer(({ store }) => {
  const { eventDateTime, eventName, nameTournament } =
    store.SportEvent.eventMainData

  const teams = eventName || 'Неизвестно'
  const tournament = nameTournament || 'Неизвестно'
  const dateTime = eventDateTime ? unixDateToDate(eventDateTime) : false
  const date = dateTime ? formatDate(dateTime) : 'Неизвестно'
  const time = dateTime ? formatTime(dateTime) : 'Неизвестно'

  return (
    <div className="container event__container">
      <div className="page-title">
        <h1>Все вилки</h1>
      </div>
      <div className="event-title">
        <div className="event-title-col">
          <div className="event-title__item">
            <Link to="/">
              <div className="event-back">
                <IconAction name="event-back" className="icon-event-back" />
              </div>
            </Link>
          </div>
        </div>
        <div className="event-title-col">
          <div className="event-title__item">
            <h3>{date}</h3>
          </div>
          <div className="event-title__item">
            <h3 className="text-align--center">{time}</h3>
          </div>
        </div>
        <div className="event-title-col">
          <div className="event-title__item">
            <h3>{teams}</h3>
          </div>
          <div className="event-title__item">{tournament}</div>
        </div>
      </div>
    </div>
  )
})

export default EventTitle
