import Main from '../../../components/layout/main/Main'

export const Feature = () => {
  return (
    <Main>
      <h1 className="main__title">Особенности</h1>
      <h2>Что такое Prematch и Live вилки?</h2>
      <p>
        Prematch вилки образуются в событиях, которые начнутся через некоторое
        время, а Live - в матчах, которые идут в данный момент времени.
      </p>
      <h2>Какой тип вилок выбрать новичку?</h2>
      <p>
        Новичкам идеально подойдет Prematch, т.к. работать с этим типом вилок
        проще. Набравшись опыта, вы сможете перейти на Live, где будете получать
        еще большую прибыль.
      </p>
      <h2>Сколько нужно денег для начала работы с вилками?</h2>
      <p>
        На первых порах вам будет вполне достаточно и €200-300, а набравшись
        опыта, Вы сможете увеличить свой игровой банк.
      </p>
    </Main>
  )
}
