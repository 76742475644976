import { IconAction } from '../../components/ui/icons/Icons'

import { Link } from 'react-router-dom'
import { Dropdown } from 'primereact/dropdown'

import { observer } from 'mobx-react-lite'

import Main from '../../components/layout/account/Main'
import ProfileLayout from '../../components/layout/account/ProfileLayout'
import Aside from '../../components/layout/account/aside/Aside'
import AsideHeader from '../../components/layout/account/aside/AsideHeader'
import AsideNavigation from '../../components/layout/account/aside/AsideNavigation'
import AsideMobileMenu from '../../components/layout/account/aside/AsideMobileMenu'

import ProfileTitle from '../../components/layout/account/ProfileTitle'

import ProfileMain from '../../components/layout/account/main/ProfileMain'
import ProfileCard from '../../components/layout/account/main/card/ProfileCard'
import ProfileCardHeader from '../../components/layout/account/main/card/header/ProfileCardHeader'
import ProfileCardHeaderTitle from '../../components/layout/account/main/card/header/ProfileCardHeaderTitle'

import BookmakersTable from '../../components/layout/account/bookmakers/BookmakersTable'
import BookmakersTableRow from '../../components/layout/account/bookmakers/BookmakersTableRow'
import BookmakersTableCol from '../../components/layout/account/bookmakers/BookmakersTableCol'
import BookmakersTableTitle from '../../components/layout/account/bookmakers/BookmakersTableTitle'
import BookmakersTableName from '../../components/layout/account/bookmakers/BookmakersTableName'
import BookmakersTableCheckbox from '../../components/layout/account/bookmakers/BookmakersTableCheckbox'

import BookmakersTableCapabilities from '../../components/layout/account/bookmakers/capabilities/BookmakersTableCapabilities'
import BookmakersTableCapabilitiesItem from '../../components/layout/account/bookmakers/capabilities/BookmakersTableCapabilitiesItem'
import { useState } from 'react'
import { useApp } from '../../hook/useApp'

const languages = [
  { label: 'Выбрать язык', value: 'Выбрать язык' },
  { label: 'Русский', value: 'Русский' },
  { label: 'Английский', value: 'Английский' },
  { label: 'Немецкий', value: 'Немецкий' }
]

const Bookmakers = observer(() => {
  const { store } = useApp()

  const [bookmakers, setBookmakers] = useState([
    { id: 1, name: '1xbet', capabilities: [1, 2], language: 'Выбрать язык' },
    {
      id: 2,
      name: 'Фонбет',
      capabilities: [1, 2, 3],
      language: 'Выбрать язык'
    },
    { id: 3, name: 'BetCity', capabilities: [1], language: 'Выбрать язык' }
  ])

  function changeLanguage(val, id) {
    const current = bookmakers.find((item) => item.id === id)
    current.language = val

    setBookmakers([...bookmakers])
  }

  const isMobile = store.UIInterface.is800px

  return (
    <Main>
      <ProfileLayout>
        <Aside>
          <AsideHeader />
          <AsideNavigation />
          {isMobile ? <AsideMobileMenu /> : ''}
        </Aside>
        {isMobile ? <ProfileTitle>Букмекеры</ProfileTitle> : ''}
        <ProfileMain>
          <ProfileCard>
            <ProfileCardHeader>
              <div className="flex-row width-max margin-bottom-m">
                <ProfileCardHeaderTitle>Зеркала</ProfileCardHeaderTitle>
                <Link
                  to="#"
                  className="profile-settings-card__b-header-settings"
                >
                  Настройки
                </Link>
              </div>
              <div className="width-max margin-none-child">
                <form
                  className="search-form profile-settings-card__b-header-search"
                  action="#"
                >
                  <input
                    className="input-el search-form__input"
                    type="search"
                    name="q"
                    required="required"
                    placeholder="Найти букмекера"
                  />
                  <button className="search-form__btn" type="submit">
                    <IconAction name="search" />
                  </button>
                </form>
              </div>
            </ProfileCardHeader>
            <BookmakersTable>
              <BookmakersTableRow type="header">
                <BookmakersTableCol>
                  <BookmakersTableTitle>Букмекер</BookmakersTableTitle>
                </BookmakersTableCol>
                <BookmakersTableCol>
                  <BookmakersTableTitle>Домен</BookmakersTableTitle>
                </BookmakersTableCol>
                <BookmakersTableCol>
                  <BookmakersTableTitle>Возможности</BookmakersTableTitle>
                </BookmakersTableCol>
                <BookmakersTableCol>
                  <BookmakersTableTitle>Язык платформы</BookmakersTableTitle>
                </BookmakersTableCol>
              </BookmakersTableRow>
              {bookmakers.map((item) => {
                return (
                  <BookmakersTableRow key={item.id} type="body">
                    <BookmakersTableCol mobile_title="Букмекер">
                      <BookmakersTableName>{item.name}</BookmakersTableName>
                    </BookmakersTableCol>
                    <BookmakersTableCol mobile_title="Домен">
                      <BookmakersTableCheckbox />
                    </BookmakersTableCol>
                    <BookmakersTableCol mobile_title="Возможности">
                      <BookmakersTableCapabilities>
                        {item.capabilities.map((item) => {
                          return (
                            <BookmakersTableCapabilitiesItem
                              key={item + '_' + item}
                              type={item}
                            />
                          )
                        })}
                      </BookmakersTableCapabilities>
                    </BookmakersTableCol>
                    <BookmakersTableCol mobile_title="Язык платформы">
                      <Dropdown
                        className="btn"
                        value={item.language}
                        options={languages}
                        onChange={(e) => changeLanguage(e.value, item.id)}
                      />
                    </BookmakersTableCol>
                  </BookmakersTableRow>
                )
              })}
            </BookmakersTable>
          </ProfileCard>
        </ProfileMain>
      </ProfileLayout>
    </Main>
  )
})

export { Bookmakers }
